import React from "react";

// reactstrap components
import { Link } from "react-router-dom";

import {
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import { getData } from "../../utils/localStorage";

export default React.memo(function MyAccountHeader() {
    const session = getData('session');
    return (
        <>
            <Container className="mt-0">
                <div className="ml-auto mr-auto">
                    <div className="text-center">
                        <h2 className="title">My Account</h2>
                    </div>
                    <hr className="hr-large"></hr>
                </div>
                <div className="myaccount-box">
                    <Row>
                        {!session.isCorporate && <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/my-profile">
                                <img
                                    className="d-none d-sm-block"
                                    alt="Whissh Image"
                                    src={require("../../assets/img/icon-account-profile.png")}
                                ></img>
                                My Profile
                              </Link>
                        </Col>}
                        {session.isCorporate && <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/corporate-info">
                                <img
                                    className="d-none d-sm-block"
                                    alt="Whissh Image"
                                    src={require("../../assets/img/icon-account-profile.png")}
                                ></img>
                                Corporate
                              </Link>
                        </Col>}
                        <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/my-booking">
                                <img
                                    className="d-none d-sm-block"
                                    alt="Whissh Image"
                                    src={require("../../assets/img/icon-account-booking.png")}
                                ></img>
                                 {!session.isCorporate? 'My Booking' : 'Booking'}
                              </Link>
                        </Col>
                        {!session.isCorporate && <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/my-request">
                                <img
                                    className="d-none d-sm-block"
                                    alt="Whissh Image"
                                    src={require("../../assets/img/icon-account-request.png")}
                                ></img>
                                 My Request
                              </Link>
                        </Col>}
                        {!session.isCorporate && <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/my-credits">
                                <img
                                    className="d-none d-sm-block"
                                    alt="Whissh Image"
                                    src={require("../../assets/img/icon-account-credits.png")}
                                ></img>
                                 My Credits
                              </Link>
                        </Col>}
                        {false && <Col md="2" sm="6" xs="6" className="myaccount-icon">

                            <Link to="/my-account/my-inbox">
                                <img
                                    className="d-none d-sm-block"
                                    alt="Whissh Image"
                                    src={require("../../assets/img/icon-account-inbox.png")}
                                ></img>
                                 My Inbox
                              </Link>
                        </Col>}
                        {!session.isCorporate && <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/my-feedback">
                                <img
                                    className="d-none d-sm-block"
                                    alt="Whissh Image"
                                    src={require("../../assets/img/icon-account-feedback.png")}
                                ></img>
                                 My Feedback
                              </Link>
                        </Col>}
                        {session.isCorporate && session.corporateRole != 'OPERATION' && <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/transaction">
                                <img
                                    className="d-none d-sm-block"
                                    alt="Whissh Image"
                                    src={require("../../assets/img/icon-account-credits.png")}
                                ></img>
                                Account Transaction
                            </Link>
                        </Col>}
                        {false && <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/invoice">
                                <img
                                    className="d-none d-sm-block"
                                    alt="Whissh Image"
                                    src={require("../../assets/img/icon-account-credits.png")}
                                ></img>
                                Invoice
                            </Link>
                        </Col>}
                        {session.isCorporate && <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/service-report">
                                <img
                                    className="d-none d-sm-block"
                                    alt="Whissh Image"
                                    src={require("../../assets/img/icon-account-credits.png")}
                                ></img>
                                Service Report
                            </Link>
                        </Col>}
                    </Row>
                </div>
            </Container>
        </>
    );
});
