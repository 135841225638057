import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, FormGroup, Input, Label } from "reactstrap";
import { getData } from "../../utils/localStorage";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App';
import './MyAccount.css'


export default function MPMyCorporateInfo() {
    const globalContext = useContext(GlobalContext);
    const [data, setData] = useState({});
    const history = useHistory();
    const session = getData('session');

    useEffect(() => {
        MPMyCorporateInfo();
    }, []);


    const MPMyCorporateInfo = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`corporate/info/${session.arnum}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    setData(result.data);
                }
            }
        }

    }

    return (
        <>
            <div className="card">
                <div className="card-header text-left title-2">
                    General Information
                </div>
                <div className="card-body">
                    <Row>
                        <Col md="4"><div className="corpinfo">Company Name:</div></Col>
                        <Col md="8">
                            <div className="corpinfo">{data.arname}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4"><div className="corpinfo">Address:</div></Col>
                        <Col md="8">
                            <div className="corpinfo">{data.addr}</div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
