import React, { useState, useContext } from "react";

// reactstrap components
 import { Link } from "react-router-dom";
import { GlobalContext } from '../../App.js';
import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink
} from "reactstrap";
// core components
import MyAccountHeader from "../../components/MyAccount/MyAccountHeader";
import MPMyPraticulars from "../../components/MyAccount/MPMyPraticulars";
import MPMyContacts from "../../components/MyAccount/MPMyContacts";
import MPMyLocation from "../../components/MyAccount/MPMyLocation";
import MPEditLocation from "../../components/MyAccount/MPEditLocation";
import MPMyCreditCards from "../../components/MyAccount/MPMyCreditCards";
import MPUpdatePassword from "../../components/MyAccount/MPUpdatePassword";
import { getData } from "../../utils/localStorage";

export default function MyProfile() {
    const globalContext = useContext(GlobalContext);
    const [active, setActive] = useState("1");
    const session = getData('session');

    const FnReturnComponent = (param) => {
        // console.log('param:  ' + param)
        switch (param) {
            case '2':
                return <MPMyContacts />
            case '3':
                return <MPMyLocation Function={{ setActive }} />
            case '3.1':
                return <MPEditLocation Function={{ setActive }} />
            case '4':
                return <MPMyCreditCards />
            case '5':
                return <MPUpdatePassword />
            default:
                // return <ModalPackageDetails Function={{ FnCloseModal: FnCloseModal, FnSetRenderModalContentType: FnSetRenderModalContentType, FnSetRenderComponentType: FnSetRenderComponentType }} />
                return <MPMyPraticulars />
        }
    }
    return (
        <>
            {!globalContext.authState.isLoggedIn ?
                null   // <Redirect to="/" /> 
                :
                <div className="adjust-navbar-breadcrumb">
                    <div className="breadcrumb-outer">
                        <Container className="breadcrumb-body">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item">
                                    <Link
                                        to="/">
                                        Home
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link
                                        to="/my-account/my-profile">
                                        <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                    My Account
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                        My Profile
                                </li>
                            </ol>
                        </Container>
                    </div>
                    <div className="myaccount-header">
                        <MyAccountHeader />
                    </div>
                    <Container fluid id="myAccount">
                        <Row>
                            <Col className="ml-auto mr-auto text-center" xl="10" lg="12" md="12">
                                <Row className="pt-30">
                                    <div className="ml-auto mr-auto">
                                        <h2 className="big-heading">User Information</h2>
                                        <hr className="hr-large"></hr>
                                    </div>
                                </Row>
                                <Row>
                                    <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                        <Row className="mt-15">
                                            <Col xl="3" lg="3" md="4" className="ml-auto mr-auto text-left">
                                                <div className="links">
                                                    <div className="text-left title-2">
                                                        My Profile
                                                 </div>
                                                    <ul className="stacked-links">
                                                        <NavItem>
                                                            <NavLink className={active === "1" ? "active" : ""}
                                                                href="#"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    setActive("1");
                                                                }}
                                                            >
                                                                <span className="fa fa-angle-right pr-15"></span>
                                                                 My Particulars
                                                        </NavLink>
                                                        </NavItem>
                                                        {false && <NavItem>
                                                            <NavLink className={active === "2" ? "active" : ""}
                                                                href="#"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    setActive("2");
                                                                }}
                                                            >
                                                                <span className="fa fa-angle-right pr-15"></span>
                                                                My Contacts
                                                            </NavLink>
                                                        </NavItem>}
                                                        {!session.isCorporate && <NavItem>
                                                            <NavLink className={active === "3" || active === "3.1" ? "active" : ""}
                                                                href="#"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    setActive("3");
                                                                }}
                                                            >
                                                                <span className="fa fa-angle-right pr-15"></span>
                                                                My Location
                                                            </NavLink>
                                                        </NavItem>}
                                                        {/* <NavItem>
                                                            <NavLink className={active === "4" ? "active" : ""}
                                                                href="#"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    setActive("4");
                                                                }}
                                                            >
                                                                <span className="fa fa-angle-right pr-15"></span>
                                                        My Credit Card
                                                     </NavLink>
                                                        </NavItem> */}
                                                        <NavItem>
                                                            <NavLink className={active === "5" ? "active" : ""}
                                                                href="#"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    setActive("5");
                                                                }}
                                                            >
                                                                <span className="fa fa-angle-right pr-15"></span>
                                                         Update Password
                                                     </NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col xl="9" lg="9" md="8" className="ml-auto mr-auto">
                                                {
                                                    FnReturnComponent(active)
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </>
    );
}
