import React, { useEffect, useContext, useRef } from "react";
import { Container, FormGroup, Label, Input, Row, Col, Button, CardHeader, NavItem, NavLink, Nav, TabContent, TabPane } from "reactstrap";
import { useHistory } from "react-router-dom";
import { GlobalContext } from '../../App';
import { ServiceBookingContext } from '../../views/ServiceBooking';
import { GetAPI, PostAPI } from "../../API/APICalling";
import { getData } from "../../utils/localStorage";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import PaynowForm from "./PaynowForm";
import CheckoutForm2c2p from "./CheckoutForm2c2p";
import CheckoutFormUKTakePay from "./CheckoutFormUkTakePay";
import { SubmitButton, ErrorMessage } from '../../components/CheckOutPayment/CheckoutProperty';
import { ValidateEmail } from "../../utils/common";
import { STRIPE_PUBLIC_KEY, STRIPE_PUBLIC_KEY_3Y } from "../../constant";
import { FnCountryCode } from '../../utils/CountryCode';
import { api } from "../../constant";

function CheckoutPayment() {
    const globalContext = useContext(GlobalContext);
    const sbContext = useContext(ServiceBookingContext);
    // const [sbContext.CheckoutState. sbContext.CheckoutDispatch] = useReducer(COReducer, COInitialState);
    const history = useHistory();
    const refC1 = useRef(null);
    const refC2 = useRef(null);
    const refC3 = useRef(null);
    const [stripePromise, setstripePromise] = React.useState(null)
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.

    const [session, setSession] = React.useState({})
    const [coy, setCoy] = React.useState('1P')

    const [clientSecret, setClientSecret] = React.useState("");

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        const session = getData('session');
        setSession(session);
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            const setStripe = async () => {
                if (await FnCheckCoy() == '3Y') {
                    setCoy('3Y');
                    setstripePromise(loadStripe(STRIPE_PUBLIC_KEY_3Y));
                }
                else {
                    setCoy('1P');
                    setstripePromise(loadStripe(STRIPE_PUBLIC_KEY));
                }
            }
            setStripe();
            if (session.stripeEnable === true && session.payNowEnable === false) {
                sbContext.CheckoutDispatch({ type: 'FIELD', field: 'paymentType', value: 'card' });
            }
            else if (session.stripeEnable === false && session.payNowEnable === true) {
                sbContext.CheckoutDispatch({ type: 'FIELD', field: 'paymentType', value: 'link' });
            }
            FnGetAvailableCredit(session.arnum);
            sbContext.CheckoutDispatch({
                type: 'SET_CART_ITEMS',
                cartList: sbContext.BookingState.cartList,
                discAmt: sbContext.BookingState.discAmt,
                promoCode: sbContext.BookingState.promoCode,
                serviceFee: sbContext.BookingState.serviceFee,
                subtotal: sbContext.BookingState.subtotal,
                shippingAmt: sbContext.BookingState.shippingAmt,
                total: sbContext.BookingState.total,
            });

            sbContext.CheckoutDispatch({ type: 'SET_ADDRESS', value: true });
            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'title', value: session.title})
            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'firstname', value: session.firstname })
            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'lastname', value: session.lastname })
            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'phoneNumber', value: session.mobile })
            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'email', value: session.email })
        }
    }, [])

    const FnCheckCoy = async () => {
        var result = await GetAPI(`booking/cart/getcoy/${sbContext.BookingState.cartList[0].refnum}`);
        //var result = await GetAPI(`booking/cart/getcoy/1`);
        if (result) {
            if (result.error) {
                sbContext.CheckoutDispatch({ type: 'ERROR' });
                globalContext.notifications('error', 'Oops ! Something went wrong, Please try again.');
                return '';
            }
            else if (result.data) {
                return result.data
            }
        }
    }

    const FnGetAvailableCredit = async (arnum) => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await GetAPI(`mycredit/total/${arnum}`);
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                sbContext.CheckoutDispatch({ type: 'ERROR' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                sbContext.CheckoutDispatch({ type: 'FIELD', field: 'availableCredit', value: result.data });
            }
        }
    }

    const paynowPayment = async (coy) => {
        try {
            var result = await PostAPI(`booking/cart/confirm/${sbContext.CheckoutState.refnum}`,
                {
                    addr1: sbContext.CheckoutState.addr1,
                    addr2: sbContext.CheckoutState.addr2,
                    addr3: sbContext.CheckoutState.addr3,
                    creditused: parseFloat(sbContext.CheckoutState.creditredeem || 0),
                    email: sbContext.CheckoutState.email,
                    firstname: sbContext.CheckoutState.firstname,
                    lastname: sbContext.CheckoutState.lastname,
                    mobile: sbContext.CheckoutState.phoneNumber,
                    paidamt: (parseFloat(sbContext.CheckoutState.total || 0) - parseFloat(sbContext.CheckoutState.creditredeem || 0)),
                    postcode: sbContext.CheckoutState.postalCode,
                    paymentref: 'card',
                    title: sbContext.CheckoutState.title
                }
            );
            if (result) {
                if (result.error) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    globalContext.notifications('error', result.error);
                    sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
                }
                else {
                    const billingDetails = {
                        email: sbContext.CheckoutState.email,
                        phone: sbContext.CheckoutState.phoneNumber,
                        name: sbContext.CheckoutState.firstname + ' ' + sbContext.CheckoutState.lastname,
                        address: {
                            country: FnCountryCode(sbContext.CheckoutState.addr3),
                            line1: sbContext.CheckoutState.unitno + ', ' + sbContext.CheckoutState.addr1,
                            line2: sbContext.CheckoutState.addr2,
                            postal_code: sbContext.CheckoutState.postalCode,
                        }
                    };

                    var url = 'checkout/initiatePaynowpayment'
                    if (coy == '3Y')
                        url = 'checkout/initiatePaynowpayment3Y'
                    api.post(url, {
                        refnum: sbContext.CheckoutState.refnum,
                        paidamt: parseFloat(sbContext.CheckoutState.total || 0),
                        creditused: parseFloat(sbContext.CheckoutState.creditredeem || 0),
                        availcredit: parseFloat(sbContext.CheckoutState.availableCredit || 0),
                        email: sbContext.CheckoutState.email || ''
                    }).then(function (result) {
                        return result.data;
                    }).then(function (responseJson) { setClientSecret(responseJson.clientSecret) });
                }
            }
        }
        catch (e) {
            sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
        }
    };

    const FnConfirmPayment = async (e) => {
        e.preventDefault();
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again');
            history.push("/");
        }
        else if (!sbContext.CheckoutState.refnum) {
            globalContext.notifications('error', 'Oops ! Something went wrong, Please try again');
            history.push("/");
        }
        else if (!sbContext.CheckoutState.title) {
            globalContext.notifications('error', 'Salutation is required.');
            return false
        }
        else if (!sbContext.CheckoutState.firstname) {
            globalContext.notifications('error', 'First Name is required.');
            return false
        }
        else if (!sbContext.CheckoutState.lastname) {
            globalContext.notifications('error', 'Last Name is required.');
            return false
        }

        else if (!sbContext.CheckoutState.phoneNumber) {
            globalContext.notifications('error', 'Mobile number is required.');
            return false
        }
        else if (!sbContext.CheckoutState.email) {
            globalContext.notifications('error', 'Email address is required.');
            return false
        }
        else if (!ValidateEmail(sbContext.CheckoutState.email)) {
            globalContext.notifications('error', 'You have entered an invalid email address.');
            return false
        }
        else if (!sbContext.CheckoutState.postalCode) {
            globalContext.notifications('error', 'Postal code is required.');
            return false
        }
        else if (!sbContext.CheckoutState.unitno) {
            globalContext.notifications('error', 'Unit number is required.');
            return false
        }
        else if (!sbContext.CheckoutState.addr1) {
            globalContext.notifications('error', 'Address is required.');
            return false
        }
        else if (!sbContext.CheckoutState.addr3) {
            globalContext.notifications('error', 'Please select country.');
            return false
        }
        else if ((sbContext.CheckoutState.finalAmount) < 0) {
            globalContext.notifications('error', 'Oops ! Something went wrong, Please try again');
            return false
        }
        else {
            //globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            if (sbContext.CheckoutState.paymentType === 'card')
                refC1.current.handleSubmit(coy);
            if (sbContext.CheckoutState.paymentType === 'card3Y')
                refC2.current.handleSubmit(coy);
            if (sbContext.CheckoutState.paymentType === 'paynow')
                refC3.current.handleSubmit(coy);

        }
    }

    return (
        <>
            <form onSubmit={FnConfirmPayment}>
                <Row>
                    <Col className="ml-auto mr-auto pt-10 pb-30" xl="9" lg="10" md="12">
                        <div className="card">
                            <div className="card-header text-left title-2">
                                Checkout
                            </div>
                            <div className="card-body p-0-xs">
                                <Container>
                                    <Row>
                                        <Col md="8" className="text-left">
                                            <p className="title-3">Billing Information</p>
                                            <Row>

                                                <Col md="6">
                                                    <FormGroup className="text-left">
                                                        {/* <Label className="badge badge-neutral">Salutation:</Label> */}
                                                        <select className="form-control"
                                                            value={sbContext.CheckoutState.title || ''}
                                                            onChange={e => {
                                                                sbContext.CheckoutDispatch({
                                                                    type: 'FIELD',
                                                                    field: 'title',
                                                                    value: e.currentTarget.value,
                                                                })
                                                            }}>
                                                            <option value="">Salutation*</option>
                                                            <option value="Mr">Mr </option>
                                                            <option value="Ms">Ms</option>
                                                            <option value="Mrs">Mrs</option>
                                                            <option value="Mdm">Mdm</option>
                                                            <option value="Dr">Dr</option>
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Input type="text" className="form-control" placeholder="First Name*"
                                                            maxLength={"15"}
                                                            value={sbContext.CheckoutState.firstname}
                                                            onChange={e =>
                                                                sbContext.CheckoutDispatch({
                                                                    type: 'FIELD',
                                                                    field: 'firstname',
                                                                    value: e.currentTarget.value,
                                                                })
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="form-group">
                                                        <Input type="text" className="form-control" placeholder="Last Name*"
                                                            maxLength={"15"}
                                                            value={sbContext.CheckoutState.lastname}
                                                            onChange={e =>
                                                                sbContext.CheckoutDispatch({
                                                                    type: 'FIELD',
                                                                    field: 'lastname',
                                                                    value: e.currentTarget.value,
                                                                })
                                                            } />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Input type="number" className="form-control" placeholder="Phone number*"
                                                            value={sbContext.CheckoutState.phoneNumber}
                                                            onChange={e =>
                                                                sbContext.CheckoutDispatch({
                                                                    type: 'NUMBER',
                                                                    digit: 10,
                                                                    field: 'phoneNumber',
                                                                    value: e.currentTarget.value,
                                                                })
                                                            } />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Input type="text" className="form-control" placeholder="E-mail*"
                                                            maxLength={"50"}
                                                            value={sbContext.CheckoutState.email}
                                                            onChange={e =>
                                                                sbContext.CheckoutDispatch({
                                                                    type: 'FIELD',
                                                                    field: 'email',
                                                                    value: e.currentTarget.value,
                                                                })
                                                            } />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup check className="mb-10">
                                                        <Label check>
                                                            <Input type="checkbox"
                                                                // checked={sbContext.CheckoutState.bookingData._saveAddress}
                                                                defaultChecked={true}
                                                                onChange={e =>
                                                                    sbContext.CheckoutDispatch({
                                                                        type: 'SET_ADDRESS',
                                                                        value: e.currentTarget.checked,
                                                                    })
                                                                }
                                                            />
                                                            <span className="form-check-sign descripyion"></span>
                                                            Same as Service address
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Input type="text" className="form-control" placeholder="Postal Code*"
                                                            maxLength={"6"}
                                                            value={sbContext.CheckoutState.postalCode}
                                                            onChange={e =>
                                                                sbContext.CheckoutDispatch({
                                                                    type: 'FIELD',
                                                                    field: 'postalCode',
                                                                    value: e.currentTarget.value,
                                                                })
                                                            } />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="10">
                                                    <FormGroup>
                                                        <Input type="text" className="form-control" placeholder="Address Line 1"
                                                            maxLength={"100"}
                                                            value={sbContext.CheckoutState.addr1}
                                                            onChange={e =>
                                                                sbContext.CheckoutDispatch({
                                                                    type: 'FIELD',
                                                                    field: 'addr1',
                                                                    value: e.currentTarget.value,
                                                                })
                                                            } />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="10">
                                                    <FormGroup>
                                                        <Input type="text" className="form-control" placeholder="Address Line 2"
                                                            maxLength={"100"}
                                                            value={sbContext.CheckoutState.addr2}
                                                            onChange={e =>
                                                                sbContext.CheckoutDispatch({
                                                                    type: 'FIELD',
                                                                    field: 'addr2',
                                                                    value: e.currentTarget.value,
                                                                })
                                                            } />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="10">
                                                    <FormGroup>
                                                        <Input type="text" className="form-control" placeholder="Unit number"
                                                            maxLength={"10"}
                                                            value={sbContext.CheckoutState.unitno}
                                                            onChange={e =>
                                                                sbContext.CheckoutDispatch({
                                                                    type: 'FIELD',
                                                                    field: 'unitno',
                                                                    value: e.currentTarget.value,
                                                                })
                                                            } />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup>
                                                <select className="form-control col-md-6"
                                                    style={{
                                                        textAlignLast: "center"
                                                    }}
                                                    value={sbContext.CheckoutState.addr3}
                                                    onChange={e =>
                                                        sbContext.CheckoutDispatch({
                                                            type: 'FIELD',
                                                            field: 'addr3',
                                                            value: e.currentTarget.value,
                                                        })
                                                    }>
                                                    <option value="">Select Country</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                </select>
                                            </FormGroup>

                                        </Col>
                                        <Col md="4">
                                            <table className="table order-details-table n-bordered">
                                                <thead>
                                                    <tr>
                                                        <th colSpan="2"><p className="title-3">Booking Summary</p></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Sub Total</td>
                                                        <td className="text-right">S$ &nbsp;<span>{sbContext.CheckoutState.subtotal.toFixed(2)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Location Fee</td>
                                                        <td className="text-right">S$ &nbsp;<span>{sbContext.CheckoutState.totalLocamt.toFixed(2)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Service Fee</td>
                                                        <td className="text-right">S$ &nbsp;<span>{sbContext.CheckoutState.serviceFee.toFixed(2)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Add-Ons</td>
                                                        <td className="text-right">S$ &nbsp;<span>{sbContext.CheckoutState.optamt.toFixed(2)}</span></td>
                                                    </tr>
                                                    {sbContext.CheckoutState.discAmt > 0 &&
                                                        <tr>
                                                            <td className="text-success">Discount  <span className="text-success">[{sbContext.CheckoutState.promoCode}]</span> </td>

                                                            <td className="text-right">S$ &nbsp;<span>{sbContext.CheckoutState.discAmt.toFixed(2)}</span></td>
                                                        </tr>
                                                    }
                                                    {sbContext.CheckoutState.shippingAmt > 0 &&
                                                        <tr>
                                                            <td>Shipping Fee</td>
                                                            <td className="text-right">S$ &nbsp;<span>{sbContext.CheckoutState.shippingAmt.toFixed(2)}</span></td>
                                                        </tr>
                                                    }

                                                    <tr className="title-3">
                                                        <td>TOTAL </td>
                                                        <td className="text-right"> S$ &nbsp; <span>{(sbContext.CheckoutState.total - sbContext.CheckoutState.creditredeem).toFixed(2)}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="8" className="text-left">
                                            <p className="title-3">Payment Information</p>
                                            <Label>Available Credits: ${(sbContext.CheckoutState.availableCredit - sbContext.CheckoutState.creditredeem).toFixed(2)}</Label>
                                            <Row>
                                                <Col md="6">
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                                <Input type="number" placeholder="$0.00"
                                                                    disabled={sbContext.CheckoutState.availableCredit > 0 ? false : true}
                                                                    value={sbContext.CheckoutState.creditredeem}
                                                                    onChange={e =>
                                                                        sbContext.CheckoutDispatch({
                                                                            type: 'REDEEM_CREDIT',
                                                                            field: 'creditredeem',
                                                                            value: e.currentTarget.value,
                                                                        })
                                                                    }

                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        {/* <Col md="4">
                                                        <FormGroup>
                                                            <button
                                                                className={"btn btn-round btn-default m-0"}
                                                                disabled={sbContext.CheckoutState.availableCredit > 0 ? false : true}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    // props.Function.FnSetRenderComponentType("CHECKOUT_PAYMENT");
                                                                    sbContext.ServiceDispatch({ type: 'CHECKOUT_PAYMENT' });
                                                                }}>
                                                                REDEEM
                                                                </button>
                                                        </FormGroup>
                                                    </Col> */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            {/*   <Label>Please select a card Type</Label>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup check className="form-check-radio">
                                                    <Label check>
                                                        <Input
                                                            defaultValue="option1"
                                                            id="exampleRadios1"
                                                            name="exampleRadios"
                                                            type="radio"
                                                        ></Input>
                                                        <span className="form-check-sign"></span>
                                                   Mastercard
                                            </Label>
                                                </FormGroup>
                                                <FormGroup check className="form-check-radio">
                                                    <Label check>
                                                        <Input
                                                            defaultChecked
                                                            defaultValue="option2"
                                                            id="exampleRadios1"
                                                            name="exampleRadios"
                                                            type="radio"
                                                        ></Input>
                                                        <span className="form-check-sign"></span>
                                                   Visa
                                            </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col md="10">
                                                <FormGroup>
                                                    <Input type="text" className="form-control" placeholder="Full Name On Card"
                                                        maxLength={"50"}
                                                        value={sbContext.CheckoutState.nameoncard}
                                                        onChange={e =>
                                                            sbContext.CheckoutDispatch({
                                                                type: 'FIELD',
                                                                field: 'nameoncard',
                                                                value: e.currentTarget.value,
                                                            })
                                                        } />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="10">
                                                <FormGroup>
                                                    <Input type="number" className="form-control" placeholder="Account Number"
                                                        value={sbContext.CheckoutState.acnumber}
                                                        onChange={e =>
                                                            sbContext.CheckoutDispatch({
                                                                type: 'NUMBER',
                                                                digit: 16,
                                                                field: 'acnumber',
                                                                value: e.currentTarget.value,
                                                            })
                                                        } />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Label>Expiration Date</Label>
                                                <InputGroup>
                                                    <Input type="number" className="col-md-5" placeholder="MM"
                                                        value={sbContext.CheckoutState.exmm}
                                                        onChange={e =>
                                                            sbContext.CheckoutDispatch({
                                                                type: 'NUMBER',
                                                                digit: 2,
                                                                field: 'exmm',
                                                                value: e.currentTarget.value,
                                                            })
                                                        } />
                                                    <span className="pt-5 pb-5"> / </span>
                                                    <Input type="number" className="col-md-5" placeholder="YYYY"
                                                        value={sbContext.CheckoutState.exyyyy}
                                                        onChange={e =>
                                                            sbContext.CheckoutDispatch({
                                                                type: 'NUMBER',
                                                                digit: 4,
                                                                field: 'exyyyy',
                                                                value: e.currentTarget.value,
                                                            })
                                                        } />
                                                </InputGroup>
                                            </Col>
                                            <Col md="4">
                                                <Label>Verification Code</Label>
                                                <FormGroup>
                                                    <Input type="number" className="form-control" placeholder="Verification Code"
                                                        value={sbContext.CheckoutState.otp}
                                                        onChange={e =>
                                                            sbContext.CheckoutDispatch({
                                                                type: 'NUMBER',
                                                                digit: 6,
                                                                field: 'otp',
                                                                value: e.currentTarget.value,
                                                            })
                                                        } />
                                                </FormGroup>
                                            </Col>
                                        </Row> */}
                                            <Row>
                                                <Col md="12">
                                                    <div className="pb-20">
                                                        <Nav
                                                            style={{ display: "flex" }}
                                                            className="nav-tabs-neutral text-left pb-0 pt-0"
                                                            data-background-color="white"
                                                            role="tablist"
                                                            tabs
                                                        >
                                                            {session.stripeEnable && coy == '1P' &&
                                                                <NavItem>

                                                                    <NavLink
                                                                        className={sbContext.CheckoutState.paymentType === "card" ? "paymenttype-tab active" : "paymenttype-tab"}
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'paymentType', value: 'card' });
                                                                        }}
                                                                    >
                                                                        Card Payment
                                                                    </NavLink>
                                                                </NavItem>
                                                            }
                                                            {session.stripeEnable && coy == '3Y' &&
                                                                <NavItem>

                                                                    <NavLink
                                                                        className={sbContext.CheckoutState.paymentType === "card3Y" ? "paymenttype-tab active" : "paymenttype-tab"}
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'paymentType', value: 'card3Y' });
                                                                        }}
                                                                    >
                                                                        Card Payment
                                                                    </NavLink>
                                                                </NavItem>
                                                            }
                                                            {/* {session.payNowEnable &&
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={sbContext.CheckoutState.paymentType === "link" ? "paymenttype-tab active" : "paymenttype-tab"}
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'paymentType', value: 'link' });
                                                                        }}
                                                                    >
                                                                        PayNow Payment
                                                                    </NavLink>
                                                                </NavItem>
                                                            } */}
                                                            {session.payNowEnable &&
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={sbContext.CheckoutState.paymentType === "paynow" ? "paymenttype-tab active" : "paymenttype-tab"}
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'paymentType', value: 'paynow' });
                                                                            paynowPayment(coy);
                                                                        }}
                                                                    >
                                                                        PayNow Payment
                                                                    </NavLink>
                                                                </NavItem>
                                                            }
                                                            {session.c2pEnable &&
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={sbContext.CheckoutState.paymentType === "2c2p" ? "paymenttype-tab active" : "paymenttype-tab"}
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'paymentType', value: '2c2p' });
                                                                        }}
                                                                    >
                                                                        2C2P Payment
                                                                    </NavLink>
                                                                </NavItem>
                                                            }
                                                            {session.ukTakepaymentsEnable &&
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={sbContext.CheckoutState.paymentType === "uktakepay" ? "paymenttype-tab active" : "paymenttype-tab"}
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            sbContext.CheckoutDispatch({ type: 'FIELD', field: 'paymentType', value: 'uktakepay' });
                                                                        }}
                                                                    >
                                                                        UK TakePay
                                                                    </NavLink>
                                                                </NavItem>
                                                            }
                                                        </Nav>
                                                    </div>
                                                    <TabContent activeTab={"paymentType" + sbContext.CheckoutState.paymentType}>
                                                        <TabPane className={sbContext.CheckoutState.paymentType === 'card' ? 'd-block' : 'd-none'}>
                                                            <Elements stripe={stripePromise}>
                                                                <CheckoutForm ref={refC1} />
                                                            </Elements>
                                                        </TabPane>
                                                        <TabPane className={sbContext.CheckoutState.paymentType === 'card3Y' ? 'd-block' : 'd-none'}>
                                                            <Elements stripe={stripePromise}>
                                                                <CheckoutForm ref={refC2} />
                                                            </Elements>
                                                        </TabPane>
                                                        <TabPane className={sbContext.CheckoutState.paymentType === 'link' ? 'd-block' : 'd-none'}>
                                                        </TabPane>
                                                        <TabPane className={sbContext.CheckoutState.paymentType === '2c2p' ? 'd-block' : 'd-none'}>
                                                            <CheckoutForm2c2p />
                                                        </TabPane>
                                                        <TabPane className={sbContext.CheckoutState.paymentType === 'uktakepay' ? 'd-block' : 'd-none'}>
                                                            <CheckoutFormUKTakePay />
                                                        </TabPane>
                                                        <TabPane className={sbContext.CheckoutState.paymentType === 'paynow' ? 'd-block' : 'd-none'}>
                                                            {clientSecret && (
                                                                <Elements options={options} stripe={stripePromise}>
                                                                    <PaynowForm ref={refC3} />
                                                                </Elements>)}
                                                        </TabPane>
                                                    </TabContent>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div className="text-center">
                                                {sbContext.CheckoutState.error && <ErrorMessage>{sbContext.CheckoutState.error.message}</ErrorMessage>}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className="card-footer text-center">
                                <Button
                                    className={"btn btn-round btn-default"}
                                    onClick={e => {
                                        e.preventDefault();
                                        //sbContext.ServiceDispatch({ type: 'DATE_TIME' });
                                        sbContext.ServiceDispatch({ type: 'FINALIZE_BOOKING' });

                                    }}>
                                    PREVIOUS
                                </Button>
                                {/* <Link
                                className="btn btn-round btn-default"
                                to={{
                                    pathname: '/service-booking',
                                    sbContext.CheckoutState. { 'redirect': 'FINALIZE' }
                                }}
                            >
                                PREVIOUS
                            </Link> */}

                                {/* {error && <ErrorMessage>{error.message}</ErrorMessage>}
                            <SubmitButton processing={processing} error={error} disabled={!stripe}>
                                Pay $25
                             </SubmitButton> */}

                                {/* <Button type="submit"
                                    className={"btn btn-round btn-info"}
                                // to="/payment-successfully" tag={Link}
                                // onSubmit={e => {
                                //     e.preventDefault();
                                //     // FnConfirmPayment();
                                //     handleSubmit();
                                // }}
                                // onClick={handleSubmit}
                                >
                                    CONFIRM PAYMENT
                              </Button> */}

                                {
                                    sbContext.CheckoutState.paymentType === 'card' &&
                                    <SubmitButton className="btn btn-round btn-info" disabled={!session.stripeEnable}
                                        processing={sbContext.CheckoutState.processing} error={sbContext.CheckoutState.error} >
                                        CONFIRM PAYMENT
                                    </SubmitButton>
                                }

                                {
                                    sbContext.CheckoutState.paymentType === 'link' &&
                                    <SubmitButton className="btn btn-round btn-info" disabled={!session.payNowEnable}>
                                        CONFIRM PAYMENT
                                    </SubmitButton>
                                }

                                {
                                    sbContext.CheckoutState.paymentType === '2c2p' &&
                                    <SubmitButton className="btn btn-round btn-info" disabled={!session.payNowEnable}>
                                        CONFIRM PAYMENT
                                    </SubmitButton>
                                }

                                {
                                    sbContext.CheckoutState.paymentType === 'uktakepay' &&
                                    <SubmitButton className="btn btn-round btn-info" disabled={!session.payNowEnable}>
                                        CONFIRM PAYMENT
                                    </SubmitButton>
                                }

                                {
                                    sbContext.CheckoutState.paymentType === 'paynow' && clientSecret &&
                                    <SubmitButton className="btn btn-round btn-info" disabled={!session.payNowEnable}>
                                        CONFIRM PAYMENT
                                    </SubmitButton>
                                }

                                {/* {
                                    sbContext.CheckoutState.paymentType === "link" ?
                                        <SubmitButton className="btn btn-round btn-info" disabled={!session.payNowEnable}>
                                            CONFIRM PAYMENT
                                     </SubmitButton>
                                        :
                                        (parseFloat(sbContext.CheckoutState.total || 0) - parseFloat(sbContext.CheckoutState.creditredeem || 0)) > 1 &&
                                        <SubmitButton className="btn btn-round btn-info" disabled={!session.stripeEnable}
                                            processing={sbContext.CheckoutState.processing} error={sbContext.CheckoutState.error} >
                                            CONFIRM PAYMENT
                                    </SubmitButton>
                                } */}

                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

export default CheckoutPayment;
