import React, { useEffect, useReducer, useContext, useState } from "react";
import { Row, Col, FormGroup, Input, Modal, ModalHeader, ModalBody, Form, Container } from "reactstrap";
import { GlobalContext } from '../../App';
import { useHistory } from "react-router-dom";
import { MyConatctListReducer, MyConatctListInitialState } from "../../reducer/MyAccountReducer";
import { getData } from "../../utils/localStorage";
import { GetAPI, DeleteAPI, PostAPI } from "../../API/APICalling";
import './MyAccount.css'

export default function MPMyContacts() {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(MyConatctListReducer, MyConatctListInitialState)
    const history = useHistory();
    const [selectedUser, setSelectedUser] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
       FnGetMyContacts();
    }, []);

    const session = getData('session');
    const FnGetMyContacts = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`myaccount/mycontacts/${session.arnum}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    // console.log(result.data)
                    dispatch({ type: 'GET_SUCCESS', payload: result.data });
                }
            }
        }
    };


    const FnDeleteContact = async (guid) => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            if (guid) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await DeleteAPI(`myaccount/mycontacts/${session.arnum}?guid=${guid}`);
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        // dispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        globalContext.notifications('success', 'Contact deleted successfully.');
                        FnGetMyContacts();
                    }
                }
            }
        }

    }

    const FnUpdateUserRole = async (user) => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            if (user) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`myaccount/mycontacts/${session.arnum}`, user);
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        // dispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        globalContext.notifications('success', 'Contact updated successfully.');
                        FnGetMyContacts();
                    }
                }
            }
        }

    }

    return (
        <>
            <div className="card">
                <div className="card-header text-left">
                    <Row>
                        <Col md="8" sm="12" xs="12" className="title-2 myaccount-icon">
                            {session.isCorporate ? 'Users' : 'My Contacts'}
                        </Col>
                        <Col md="4" sm="12" xs="12" className="myaccount-icon  pull-right">
                            <FormGroup className="search">
                                <Input type="search" placeholder="Search.."
                                    value={state.searchString}
                                    onChange={e =>
                                        dispatch({
                                            type: 'FILTER_RESULT',
                                            field: 'searchString',
                                            value: e.currentTarget.value,
                                        })
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive table-list-view" id="myContact">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="text-left">Name</th>
                                    <th className="text-left">Phone</th>
                                    <th className="text-left">Email</th>
                                    {session.isCorporate && <th className="text-left">Type</th>}
                                    <th className="text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.FilterContacts &&
                                    <React.Fragment>
                                        {
                                            state.FilterContacts.length < 1 ?
                                                <tr>
                                                    <td colSpan="5" className="text-center empty-screen">No Records Found</td></tr>
                                                :
                                                <React.Fragment>
                                                    {
                                                        state.FilterContacts.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="text-left contactName">{item.firstname} {item.lastname}</td>
                                                                <td className="text-left">{item.mobile}</td>
                                                                <td className="text-left">{item.email}</td>
                                                                {session.isCorporate &&  <td className="text-left contactType">{item.ctype}</td>}
                                                                <td className="text-left">
                                                                    {session.isCorporate && <span className="pr-10">
                                                                        <img
                                                                            alt="Whissh Image"
                                                                            className="rounded-circle"
                                                                            src={require("../../assets/img/icon-sm-blue-user.png")}
                                                                            style={{ maxHeight: "18px", paddingRight: "10px" }}
                                                                            title="Assign User Role"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                setSelectedUser(item);
                                                                                setShowModal(true);
                                                                            }}
                                                                        ></img>
                                                                    </span>}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {showModal &&
                <Modal size="xl" isOpen={showModal}>
                    <ModalHeader toggle={() => { setShowModal(false) }}></ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col xl="6" lg="6" md="12" className="ml-auto mr-auto">
                                    <Form>
                                        <div className="title-3">User - {selectedUser.firstname} {selectedUser.lastname}</div>
                                        <label className="badge badge-neutral">User Role</label>
                                        <select className="form-control"
                                            value={selectedUser.ctype}
                                            onChange={e => {
                                                setSelectedUser({ ...selectedUser, ctype: e.currentTarget.value })
                                            }}>
                                            <option value="ADMIN">ADMIN</option>
                                            <option value="OPERATION">OPERATION</option>
                                            <option value="ACCOUNT">ACCOUNT</option>
                                        </select>
                                        {selectedUser.ctype == "ADMIN" && <div className="roleinfo">User of ADMIN role has full access to all features:
                                            <ul>
                                                <li>View and assign role to corporate users</li>
                                                <li>View account transactions</li>
                                                <li>View and endorse service reports</li>
                                            </ul></div>}
                                        {selectedUser.ctype == "OPERATION" && <div className="roleinfo">User of OPERATION role is able to:
                                            <ul>
                                                <li>View and request to cancel service sessions</li>
                                                <li>View and endorse service reports</li>
                                            </ul></div>}
                                            {selectedUser.ctype == "ACCOUNT" && <div className="roleinfo">User of ACCOUNT role is able to:
                                            <ul>
                                                <li>View service sessions</li>
                                                <li>View account transactions</li>
                                                <li>View service reports</li>
                                            </ul></div>}
                                        <button
                                            className="btn btn-round btn-info"
                                            onClick={e => {
                                                e.preventDefault();
                                                FnUpdateUserRole(selectedUser);
                                                setShowModal(false);
                                            }}
                                        >
                                            UPDATE
                                        </button>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
            }
        </>
    );
}
