import React, { useEffect, useContext, useReducer } from "react";

// reactstrap components
import { Link, useHistory } from "react-router-dom";

import { Container, Row, Col, Button, FormGroup, Input, Label, CardHeader, NavItem, NavLink, Nav, TabContent, TabPane } from "reactstrap";
import { GlobalContext } from '../../App.js';
import { getData } from '../../utils/localStorage'

import { validDate, pastDate, validDate2, beetweenDate, DateFormat, } from '../../utils/common'

// react plugins that creates an input with a date picker
import Datetime from "react-datetime";
// core components
import MyAccountHeader from "../../components/MyAccount/MyAccountHeader";
import { ServiceReportInitialState, ServiceReportListReducer } from "../../reducer/MyAccountReducer";
import { GetAPI } from "../../API/APICalling";
import queryString from 'query-string'

export default function SOA() {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(ServiceReportListReducer, ServiceReportInitialState);

    const history = useHistory();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    useEffect(() => {
        dispatch({ type: 'FIELD', field: 'datefrom', value: Datetime.moment() })
        dispatch({ type: 'FIELD', field: 'dateto', value: Datetime.moment().add(30, 'day') })
        FnGetSOA(Datetime.moment(), Datetime.moment().add(30, 'day'));
    }, []);

    const FnGetSOA = async (datefrom, dateto) => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`corporate/transaction/${session.arnum}?datefrom=${DateFormat(datefrom, 'YYYY-MM-DD')}&dateto=${DateFormat(dateto, 'YYYY-MM-DD')}`);
            // var result = await APIHistoryBooking(session.arnum);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    // setHistoryData(result.data);
                    dispatch({ type: 'DATA_SUCCESS', payload: result.data });
                }
            }
        }
    };

    const FnGetInvoicePdf = async (refnum) => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`corporate/invoicepdf/${refnum}`);
            // var result = await APIHistoryBooking(session.arnum);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    // setHistoryData(result.data);
                    console.log(result.data)
                    if (result.data.s3link) {
                        window.open(result.data.s3link, '_blank').focus();
                    }
                    else {
                        globalContext.notifications('success', result.data.message);
                    }
                }
            }
        }
    };

    let balance = 0;
    return (
        <>

            <div className="adjust-navbar-breadcrumb">
                <div className="breadcrumb-outer">
                    <Container className="breadcrumb-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link
                                    to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link
                                    to="/my-account/my-profile">
                                    <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                    My Account
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                Account Transactions
                            </li>
                        </ol>
                    </Container>
                </div>
                <div className="myaccount-header">
                    <MyAccountHeader />
                </div>
                <Container fluid id="myAccount">
                    <Row>
                        <Col className="ml-auto mr-auto text-left" xl="10" lg="12" md="12">
                            <Row className="pt-30">
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <h2 className="big-heading">Account Transactions</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <Row className="mt-15">
                                        <Col md="12" className="ml-auto mr-auto">
                                            <div className="card" id="myBooking">
                                                <div className="card-body">
                                                    <div>
                                                        <Row>
                                                            <Col md="3" xs="12">
                                                                <Label className="badge badge-neutral">Date From:</Label>
                                                                <FormGroup>
                                                                    <Datetime
                                                                        timeFormat={false}
                                                                        dateFormat="YYYY-MM-DD"
                                                                        inputProps={{ placeholder: "YYYY-MM-DD" }}
                                                                        closeOnSelect={true}
                                                                        value={state.datefrom}
                                                                        //value={state.reqdate}
                                                                        isValidDate={e => state.activeTab === 'active' ? validDate(e) : pastDate(e)}
                                                                        onChange={e =>
                                                                            dispatch({
                                                                                type: 'DATEFROM_FIELD',
                                                                                field: 'datefrom',
                                                                                value: e._d,
                                                                            })
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>


                                                            <Col md="3" xs="12">
                                                                <Label className="badge badge-neutral">Date To:</Label>
                                                                <FormGroup>
                                                                    <Datetime
                                                                        timeFormat={false}
                                                                        dateFormat="YYYY-MM-DD"
                                                                        inputProps={{ placeholder: "YYYY-MM-DD" }}
                                                                        closeOnSelect={true}
                                                                        value={state.dateto}
                                                                        //value={state.reqdate}
                                                                        // isValidDate={validDate}
                                                                        isValidDate={e => state.activeTab === 'active' ? (validDate2(e, (Datetime.moment(state.datefrom)))) : (beetweenDate(e, state.datefrom))}
                                                                        onChange={e =>
                                                                            dispatch({
                                                                                type: 'FIELD',
                                                                                field: 'dateto',
                                                                                value: e._d,
                                                                            })
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="3" xs="12">
                                                                <FormGroup className="filter">
                                                                    <Button className="btn btn-round btn-info"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            FnGetSOA(state.datefrom, state.dateto);
                                                                        }}

                                                                    >Filter</Button>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="table-responsive table-list-view" id="myBookingTable">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-left">Date</th>
                                                                    <th className="text-left">Transaction</th>
                                                                    <th className="text-left">Reference #</th>
                                                                    <th className="text-right">Invoice Amount</th>
                                                                    <th className="text-right">Credit</th>
                                                                    <th className="text-right">Outstanding Balance</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {state.data &&
                                                                    <React.Fragment>
                                                                        {
                                                                            state.data.length > 0 &&
                                                                            <React.Fragment>
                                                                                {
                                                                                    state.data.map((item, index) => { 
                                                                                        balance = balance + item.balance
                                                                                        return(
                                                                                        <tr key={index}>
                                                                                            <td className="text-left">{DateFormat(item.trandate, 'DD-MMM-YYYY')}</td>
                                                                                            <td className="text-left">{item.transaction}</td>
                                                                                            <td className="text-left"><a style={{cursor:"pointer", color:"blue"}} onClick={e => { e.preventDefault(); FnGetInvoicePdf(item.refnum) }}>{item.refnum}</a></td>
                                                                                            <td className="text-right">{item.dramt.toFixed(2)}</td>
                                                                                            <td className="text-right">{item.cramt.toFixed(2)}</td>
                                                                                            <td className="text-right">{balance.toFixed(2)}</td>
                                                                                        </tr>
                                                                                    )})
                                                                                }
                                                                            </React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
