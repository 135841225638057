import React, { useEffect, useReducer, useContext } from "react";
import { Row, Col, Button, FormGroup, Input, Label } from "reactstrap";
import { GlobalContext } from '../../App';
import { useHistory } from "react-router-dom";
import { MyLocationReducer, MyLocationInitialState } from "../../reducer/MyAccountReducer";
import { getData } from "../../utils/localStorage";
import { GetAPI, PostAPI, DeleteAPI } from "../../API/APICalling";

export default function MPMyLocation(props) {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(MyLocationReducer, MyLocationInitialState)
    const history = useHistory();
    const session = getData('session');

    useEffect(() => {
        FnGetMyLocationList()
        FnGetMyContacts()
    }, []);

    const FnGetMyContacts = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`myaccount/mylocations/PTC/${session.arnum}?email=${session.email}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    dispatch({ type: 'CONTACT_SUCCESS', payload: result.data });
                }
            }
        }
    };

    const FnGetMyLocationList = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`myaccount/mylocations/${session.arnum}?email=${session.email}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    console.log(result.data)
                    dispatch({ type: 'GET_SUCCESS', payload: result.data });
                }
            }
        }
    };

    const FnUpdateLocation = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            if (!state.LocationData.locationname) {
                globalContext.notifications('error', 'Location name is required.');
                return false
            }
            if (!state.LocationData.addr1) {
                globalContext.notifications('error', 'First address is required.');
                return false
            }
            if (!state.LocationData.addr3) {
                globalContext.notifications('error', 'Please select country.');
                return false
            }
            if (!state.LocationData.housetype) {
                globalContext.notifications('error', 'House type is required.');
                return false
            }
            if (!state.LocationData.area) {
                globalContext.notifications('error', 'Property size is required.');
                return false
            }
            if (state.LocationData) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`myaccount/mylocations/${session.arnum}?locationId=${state.LocationData.locationid}`,
                    state.LocationData
                );
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        dispatch({ type: 'LIST' });
                        FnGetMyLocationList();
                        globalContext.notifications('success', 'Location Updated successfully.');
                    }
                }
            }
        }

    }

    const FnDeleteLocation = async (locationId) => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            if (locationId) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await DeleteAPI(`myaccount/mylocations/${session.arnum}?locationId=${locationId}`);
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        dispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        globalContext.notifications('success', 'Location deleted successfully.');
                        FnGetMyLocationList();
                    }
                }
            }
        }

    }

    return (
        <>
            {
                state.isEdit ?
                    <div className="card">
                        <div className="card-header text-left title-2">
                            Edit Location
                    </div>
                        <div className="card-body">
                            <Row>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">Location Name:</Label>
                                        <Input type="text" className="form-control" placeholder="Location Name"
                                            maxLength={20}
                                            value={state.LocationData.locationname}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'locationname',
                                                    value: e.currentTarget.value,
                                                })
                                            } />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">Address 1:</Label>
                                        <Input type="text" className="form-control" placeholder="Address 1*"
                                            maxLength={100}
                                            value={state.LocationData.addr1}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'addr1',
                                                    value: e.currentTarget.value,
                                                })
                                            } />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">Address 2:</Label>
                                        <Input type="text" className="form-control" placeholder="Address 2"
                                            maxLength={100}
                                            value={state.LocationData.addr2}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'addr2',
                                                    value: e.currentTarget.value,
                                                })
                                            } />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">Country:</Label>
                                        <select className="form-control"
                                            style={{
                                                textAlignLast: "center"
                                            }}
                                            value={state.LocationData.addr3}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'addr3',
                                                    value: e.currentTarget.value,
                                                })
                                            } >
                                            <option value="">Select Country</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">Unit Number:</Label>
                                        <Input type="text" className="form-control" placeholder="Unit Number"
                                            maxLength={20}
                                            value={state.LocationData.unitno}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'unitno',
                                                    value: e.currentTarget.value,
                                                })
                                            } />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">Postal Code:</Label>
                                        <Input type="number" className="form-control" placeholder="Postal Code"
                                            maxLength={6}
                                            value={state.LocationData.postcode}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'postcode',
                                                    value: e.currentTarget.value,
                                                })
                                            } />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">House type:</Label>
                                        <select className="form-control"
                                            style={{
                                                textAlignLast: "center"
                                            }}

                                            maxLength={20}
                                            value={state.LocationData.housetype}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'housetype',
                                                    value: e.currentTarget.value,
                                                })
                                            }>
                                            <option value="">Select House type</option>
                                            <option value="Apartment">Apartment</option>
                                            <option value="Landed">Landed</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">Property Size:</Label>
                                        <Input type="number" className="form-control" placeholder="Property Size"
                                            maxLength={10}
                                            value={state.LocationData.area}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'area',
                                                    value: parseInt(e.currentTarget.value || 0)
                                                })
                                            } />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">No. of bedrooms:</Label>
                                        <Input type="number" className="form-control" placeholder="Number of bedrooms"
                                            maxLength={10}
                                            value={state.LocationData.bedroomqty}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'bedroomqty',
                                                    value: parseInt(e.currentTarget.value || 0),
                                                })
                                            } />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">No. of bathrooms:</Label>
                                        <Input type="number" className="form-control" placeholder="Number of bathrooms"
                                            maxLength={10}
                                            value={state.LocationData.bathroomqty}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'bathroomqty',
                                                    value: parseInt(e.currentTarget.value || 0),
                                                })
                                            } />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">Owner Name:</Label>
                                        <select className="form-control"
                                            style={{
                                                textAlignLast: "center"
                                            }}
                                            value={state.LocationData.ptc}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'ptc',
                                                    value: e.currentTarget.value,
                                                })
                                            }>
                                            {/* <option value="">Select Country</option> */}
                                            {state.ContactList.map((item, index) => (
                                                <option key={index} value={item.ptc}>{item.ptcname}</option>
                                            ))}

                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup check className="text-left">
                                        <Label className="badge badge-neutral">Mark as Default address:</Label><br />
                                        <Label check>
                                            <Input type="checkbox"
                                                checked={state.LocationData.isDefault || false}
                                                onChange={e =>
                                                    dispatch({
                                                        type: 'FIELD',
                                                        field: 'isDefault',
                                                        value: e.currentTarget.checked,
                                                    })
                                                }
                                            />
                                            <span className="form-check-sign description"></span>
                                                is Default
                                                    </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup className="text-left">
                                        <Label className="badge badge-neutral">Remarks:</Label>
                                        <Input type="textarea" className="form-control" placeholder="Special Instruction"
                                            maxLength={250}
                                            value={state.LocationData.specialinstruction}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FIELD',
                                                    field: 'specialinstruction',
                                                    value: e.currentTarget.value,
                                                })
                                            } />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-footer">
                            <Row>
                                <Col md="12" className="text-cenetr">
                                    <FormGroup>
                                        <Button className="btn btn-round btn-info"
                                            onClick={e => {
                                                e.preventDefault();
                                                dispatch({ type: 'LIST' })
                                            }}
                                        >BACK</Button>
                                        <Button className="btn btn-round btn-info"
                                            onClick={e => {
                                                e.preventDefault();
                                                props.Function.setActive("3");
                                                FnUpdateLocation();
                                            }}
                                        >UPDATE LOCATION</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    :
                    <div className="card">
                        <div className="card-header text-left">
                            <Row>
                                <Col md="8" sm="12" xs="12" className="title-2">
                                    {session.isCorporate ? 'Locations' : 'My Addresses'}
                        </Col>
                                <Col md="4" sm="12" xs="12" className="pull-right">
                                    <FormGroup className="search">
                                        <Input type="search" placeholder="Search.."
                                            value={state.searchString}
                                            onChange={e =>
                                                dispatch({
                                                    type: 'FILTER_RESULT',
                                                    field: 'searchString',
                                                    value: e.currentTarget.value,
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-body p-0">
                            <div className="table-responsive table-list-view" id="myContactTable">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Location Name</th>
                                            <th className="text-left">Address</th>
                                            <th className="text-left">Property Type</th>
                                            {/* <th className="text-left">Person In Charge</th> */}
                                            <th className="text-left">Is Default</th>
                                            <th className="text-left">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {state.FilterLocationResult &&
                                            <React.Fragment>
                                                {
                                                    state.FilterLocationResult.length < 1 ?
                                                        <tr>
                                                            <td colSpan="5" className="text-center empty-screen">No Records Found</td>
                                                        </tr>
                                                        :
                                                        <React.Fragment>
                                                            {
                                                                state.FilterLocationResult.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="text-left">{item.locationname}</td>
                                                                        <td className="text-left">{item.fulladdr}</td>
                                                                        <td className="text-left">{item.housetype}</td>
                                                                        {/* <td className="text-left">Thu Hang</td> */}
                                                                        <td className="text-center">
                                                                            <Input type="checkbox"
                                                                                readOnly={true}
                                                                                checked={item.isDefault}
                                                                            />
                                                                        </td>
                                                                        <td className="text-left">
                                                                            <div style={{ display: 'flex' }}>
                                                                                <span>
                                                                                    <a href="#"
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            //props.Function.setActive("3.1");
                                                                                            dispatch({ type: 'EDIT', payload: item });
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            alt="Whissh Image"
                                                                                            className="rounded-circle"
                                                                                            src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                            style={{ maxHeight: "18px", paddingRight: "10px" }}
                                                                                        ></img>
                                                                                    </a>
                                                                                </span>
                                                                                <span>
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                                                        style={{ maxHeight: "14px" }}
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            FnDeleteLocation(item.locationid);
                                                                                        }}
                                                                                    ></img>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }

                                        {/* <tr>
                                    <td className="text-left">Home</td>
                                    <td className="text-left">987654, 01 Street Name #01-01 Singapore</td>
                                    <td className="text-left">Landed</td>
                                    <td className="text-left">Spencer</td>
                                    <td className="text-left contactType">Crew</td>
                                    <td className="text-center pt-5 pb-5">
                                        <span>
                                            <a href="#"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    props.Function.setActive("3.1");
                                                }}
                                            >
                                                <img
                                                    alt="Whissh Image"
                                                    className="rounded-circle"
                                                    src={require("../../assets/img/icon-sm-blue-view.png")}
                                                    style={{ maxHeight: "25px", margin: "5px" }}
                                                ></img>
                                            </a>
                                        </span>
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                style={{ maxHeight: "14px" }}
                                            ></img>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left">Home</td>
                                    <td className="text-left">987654, 01 Street Name #01-01 Singapore</td>
                                    <td className="text-left">Apartment)</td>
                                    <td className="text-left">Thu Hang</td>
                                    <td className="text-left contactType">CrXXXew</td>
                                    <td className="text-center pt-5 pb-5">
                                        <span>
                                            <a href="#"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    props.Function.setActive("3.1");
                                                }}
                                            >
                                                <img
                                                    alt="Whissh Image"
                                                    className="rounded-circle"
                                                    src={require("../../assets/img/icon-sm-blue-view.png")}
                                                    style={{ maxHeight: "25px", margin: "5px" }}
                                                ></img>
                                            </a>
                                        </span>
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                style={{ maxHeight: "14px" }}
                                            ></img>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left">Home</td>
                                    <td className="text-left">987654, 01 Street Name #01-01 Singapore</td>
                                    <td className="text-left">Landed</td>
                                    <td className="text-left">Spencer</td>
                                    <td className="text-left contactType">Crew</td>
                                    <td className="text-center pt-5 pb-5">
                                        <span>
                                            <a href="#"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    props.Function.setActive("3.1");
                                                }}
                                            >
                                                <img
                                                    alt="Whissh Image"
                                                    className="rounded-circle"
                                                    src={require("../../assets/img/icon-sm-blue-view.png")}
                                                    style={{ maxHeight: "25px", margin: "5px" }}
                                                ></img>
                                            </a>
                                        </span>
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                style={{ maxHeight: "14px" }}
                                            ></img>
                                        </span>
                                    </td>
                                </tr>
                            */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}
